import React from "react";
import Page from "../../components/Page";
// import "./DesignPage.scss";

const DesignPage = () => {
  return (
    <Page name="Design" seo={{ title: "Design" }}>
      <div className="d-flex align-center justify-center">
        <div>Design page</div>
      </div>
    </Page>
  );
};

export default DesignPage;
